"use client";

import { usePathname } from "next/navigation";
import Script from "next/script";
import { useEffect, useState } from "react";

const pageview = () => {
	window.fbq("track", "PageView");
};

const event = (name, options = {}) => {
	window.fbq("track", name, options);
};

const FacebookPixel = () => {
	const [loaded, setLoaded] = useState(false);
	const pathname = usePathname();

	useEffect(() => {
		if (!loaded) return;

		pageview();
	}, [pathname, loaded]);

	return (
		<div>
			<Script
				id="fb-pixel"
				src="/scripts/pixel.js"
				strategy="afterInteractive"
				onLoad={() => setLoaded(true)}
				data-pixel-id="835613110216090"
			/>
		</div>
	);
};

export default FacebookPixel;
