"use client";
import React from "react";
import { Viewport } from "next";
import "shared/styles/output.scss";
import { APP_SITE } from "../shared/config/environment";
import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter";
import NextTopLoader from "nextjs-toploader";
import { GoogleTagManager } from "@next/third-parties/google";
import { GTM_ID } from "@/utils/gtm";
import dynamic from "next/dynamic";
import { useParams } from "next/navigation";
import { parsePathToApplication } from "@/utils";
import FacebookPixel from "@/components/FacebookPixel";

export const viewport: Viewport = {
	width: "device-width",
	initialScale: 1,
	maximumScale: 5,
};

const CookieBanner = dynamic(() => import("@/components/CookieBanner"));

export default function RootLayout({
	children,
}: {
	children: React.ReactNode;
}) {
	const params = useParams();
	const args = parsePathToApplication(
		Array.isArray(params.path) ? params.path : [params.path]
	);
	return (
		<html lang={args.locale.language} className="w-full h-full">
			<body className={`font-inter overflow-x-hidden w-full h-full`}>
				{/* <DatadogInit /> */}
				{!["media", "consumer"].includes(APP_SITE) && (
					<>
						<GoogleTagManager gtmId={GTM_ID.main} />
						{APP_SITE == "jobs" && (
							<GoogleTagManager gtmId={GTM_ID.careers} />
						)}
						<CookieBanner />
						<FacebookPixel />
					</>
				)}
				<NextTopLoader showSpinner={false} color={"#FF171E"} />
				<AppRouterCacheProvider>{children}</AppRouterCacheProvider>
			</body>
		</html>
	);
}
